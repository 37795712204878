import Carousel from "react-bootstrap/Carousel";
import "animate.css";
import "./HeroSlider.css";
import { Container, Row } from "react-bootstrap";
function HeroSlider() {
  return (
    <Container fluid>
      <Row>
        <Carousel>
          <Carousel.Item>
            <div className="slider_img_one"></div>
            <Carousel.Caption>
              <div className="slidercontent">
                <h1 className="animate__animated animate__fadeInRightBig">
                  Manufacturer & exporter of
                </h1>
                <p className="animate__animated animate__fadeInLeftBig">
                  All kinds of apparel & readymade garments goods (knitwear,
                  denim, woven items) for Men, Women, Kids.
                </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div className="slider_img_two"></div>
            <Carousel.Caption>
              <div className="slidercontent">
                <h1 className="animate__animated animate__fadeInTopLeft">
                  Fashion is an art form & JNC is the innovative art-hub.
                </h1>
                {/* <p className="animate__animated animate__fadeInTopRight">
              Nulla vitae elit libero, a pharetra augue mollis interdum.
            </p> */}
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div className="slider_img_three"></div>
            <Carousel.Caption>
              <div className="slidercontent">
                <h1 className="animate__animated animate__zoomInDown">
                  Quality assurance | Lead timing | Fair Trade
                </h1>
                {/* <p className="animate__animated animate__rotateInUpRight">
              Nulla vitae elit libero, a pharetra augue mollis interdum.
            </p> */}
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div className="slider_img_four"></div>
            <Carousel.Caption>
              <div className="slidercontent">
                {/* <h2 className="animate__animated animate__zoomInDown">
              Quality assurance | Lead timing | Fair Trade
            </h2> */}
                {/* <p className="animate__animated animate__rotateInUpRight">
              Nulla vitae elit libero, a pharetra augue mollis interdum.
            </p> */}
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </Row>
    </Container>
  );
}

export default HeroSlider;
