import React from "react";
import "../Pages/Pages.css";
import { Container, Row } from "react-bootstrap";

import "../Pages/Pages.css";
import ceoImg from "../assets/images/ceo.jpeg";

export default function Management() {
  return (
    <div>
      <div className="our_management_bg">
        <Container>
          <Row>
            <div className="col-md-6 col-sm-6 col-12">
              <div className="ceo_message">
                <div className="quote">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="56"
                    height="56"
                    fill="currentColor"
                    className="bi bi-quote"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z" />
                  </svg>
                  <h5>Words of wisdom from our CEO</h5>
                  <p>
                    JNC Fashion Ltd. has been emerging over the years as a
                    trusted sourcing as well as manufacturing partner for global
                    clothing buyers, retailers & brands along with ingenuity,
                    uniqueness, managing supply chain, and well-planned
                    operations. JNC has a large network of factories that have
                    been technically and ethically audited to industry
                    standards. We are specialized in Knitwear, Sweater, and
                    Woven items. All are composite top-class factories with all
                    modern facilities & internationally certified. We have
                    Experienced and highly Technical Management to execute the
                    product exactly as per the buyer’s requirements. Our
                    management concentrates fully on buyer satisfaction. Quality
                    is our main strength that is why we believe in a long-term
                    relationship. As the Chief Executive Officer (C.E.O) of the
                    JNC group, me & my team is profoundly dedicated &
                    enthusiastic to take on new challenges. Overcoming hurdles
                    and reaping opportunities, which would not have been
                    possible without the concerted effort of my entire team, we
                    have honed the deliverance of premium quality clothing
                    materials & goods.
                  </p>
                  <h6 className="text-end">
                    --- MR. A.K.AZAD, C.E.O, JNC Group.
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-12">
              <div className="ceo_img_wrapper">
                <img className="img-fluid" src={ceoImg} alt="" />
                <div className="img_border"></div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
}
